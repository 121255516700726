#column {
    z-index: -1;
    background-color: #FFFFFF;
    border: .5px solid #cecece;
    border-radius: 7px;
    padding: 11px 11px 13px 11px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#column-color-swatch {
    height: 7px;
    border-radius: 50px;
    margin-bottom: 5px;
}

.red {
    background-color: #a11800;
}

.orange {
    background-color: #a15600;
}

.yellow {
    background-color: #b08307;
}

.green {
    background-color: #3a8501;
}

.blue {
    background-color: #016285;
}

.purple {
    background-color: #450185;
}

.lightgray {
    background-color: #cecece;
}

.medgray {
    background-color: #8f8f8f;
}

.darkgray {
    background-color: #686868;
}

.black {
    background-color: #000000;
}

#column-title-and-btns {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 5px 0 5px;
}

#column-title {
    font-size: 18.5px;
}

#column-btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2px;
}

#column-btns button {
    border-radius: 6px;
    background-color: #FFFFFF;
}

#column-btns button:hover {
    background-color: #ededed;
}

#column-btns i {
    color: #707070;
}

#column-btn-add-card {
    margin-top: 8px;
    align-self: end;
}

#column-btn-add-card button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    padding: 12.5px;
    margin-top: -3px;
    border-radius: 9px;
    background-color: #ededed;
    width: 253px;
}

#column-btn-add-card button:hover {
    background-color: #e5e5e5;
}
