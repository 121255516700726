.note-form-top-header {
    font-size: 24px;
    padding: 15px 0 5px 3px;
}

.note-error-text {
    color: rgba(159, 0, 0, 0.778);
    font-size: 11.5px;
}

#color-btns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 33px;
}

/* #color-btn-div {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ffffff;
}

#color-btn-div:hover {
    border: 2px solid #d3d3d3;
    box-shadow: 2px 2px 10px #b0b0b0;
} */

/* #color-btn-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
} */

#red {
    background-color: #a11800;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ffffff;
}

#red:hover {
    border: 2px solid #d3d3d3;
    box-shadow: 2px 2px 10px #b0b0b0;
}

#orange {
    background-color: #a15600;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ffffff;
}

#orange:hover {
    border: 2px solid #d3d3d3;
    box-shadow: 2px 2px 10px #b0b0b0;
}

#yellow {
    background-color: #b08307;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ffffff;
}

#yellow:hover {
    border: 2px solid #d3d3d3;
    box-shadow: 2px 2px 10px #b0b0b0;
}

#green {
    background-color: #3a8501;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ffffff;
}

#green:hover {
    border: 2px solid #d3d3d3;
    box-shadow: 2px 2px 10px #b0b0b0;
}

#blue {
    background-color: #016285;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ffffff;
}

#blue:hover {
    border: 2px solid #d3d3d3;
    box-shadow: 2px 2px 10px #b0b0b0;
}

#purple {
    background-color: #450185;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ffffff;
}

#purple:hover {
    border: 2px solid #d3d3d3;
    box-shadow: 2px 2px 10px #b0b0b0;
}

#lightgray {
    background-color: #cecece;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ffffff;
}

#lightgray:hover {
    border: 2px solid #d3d3d3;
    box-shadow: 2px 2px 10px #b0b0b0;
}

#medgray {
    background-color: #8f8f8f;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ffffff;
}

#medgray:hover {
    border: 2px solid #d3d3d3;
    box-shadow: 2px 2px 10px #b0b0b0;
}

#darkgray {
    background-color: #686868;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ffffff;
}

#darkgray:hover {
    border: 2px solid #d3d3d3;
    box-shadow: 2px 2px 10px #b0b0b0;
}

#black {
    background-color: #000000;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ffffff;
}

#black:hover {
    border: 2px solid #d3d3d3;
    box-shadow: 2px 2px 10px #b0b0b0;
}

.selected {
    border: 2px solid #8d8d8d !important;
    box-shadow: 2px 2px 10px #b0b0b0;
}

.color-bg-checkmark {
    color: white;
    margin: 16px 0 0 18px;
}
