#demo-user-link {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    color: #283c5c;
    transition: color .3s;
    cursor: pointer;
}

#demo-user-link:hover {
    color: #60ac44;
    /* text-decoration: underline; */
}
