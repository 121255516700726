nav {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 61px;
  position: sticky;
  top: 0px;
  /* box-shadow: 0px 5px 25px rgba(44, 44, 255, 0.2); */
  box-shadow: 0px 5px 30px rgba(99, 99, 99, 0.2);
  background-color: #FFFFFF;
}

#nav-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}

#nav-logo-text {
  font-size: 30px;
  font-weight: 600;
  margin-left: 20px;
  color: #283c5c;
}

#nav-logo-text:hover {
  background-image: linear-gradient(-225deg,
      #283c5c 30%,
      #083f98 10%,
      #2596be 70%,
      #21314c 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4.5s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

#nav-features {
  font-size: 17px;
}

#nav-plans {
  font-size: 17px;
}

#nav-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 13px;
}

#nav-dashboard {
  font-size: 19px;
  font-weight: 500;
  color: #182c4c;
  background-color: white;
  margin-right: 12px;
  transition: color .5s;
}

#nav-dashboard:hover {
  color: #0844a4;
}

#nav-login button {
  font-size: 19px;
  font-weight: 500;
  color: #182c4c;
  background-color: white;
  transition: color .5s;
}

#nav-login button:hover {
  color: #0844a4;
}

#nav-signup button {
  font-size: 19px;
  height: 61px;
  color: #FFFFFF;
  background-color: #0864fc;
  padding: 0 18px;
  border-radius: 0;
  transition: background-color .5s;
}

#nav-signup button:hover {
  background-color: #0844a4;
}

#nav-profile-dropdown-btn {
  font-size: 19px;
  height: 61px;
  width: 69px;
  color: #FFFFFF;
  background-color: #0844a4;
  padding: 0 18px;
  border-radius: 0;
  transition: background-color .5s;
}

#nav-profile-dropdown-btn:hover {
  background-color: #0864fc;
}

#nav-profile-dropdown-btn i {
  font-size: 23px;
  color: #FFFFFF;
}

.nav-profile-dropdown {
  position: absolute;
  top: 61px;
  right: 0px;
  height: 250px;
  width: 110px;
  padding: 25px 50px;
  background-color: #FFFFFF;
  /* box-shadow: 10px 0px 100px #e5e5e5; */
  /* box-shadow: -7px 7px 25px rgba(44, 44, 255, 0.15); */
  box-shadow: -7px 7px 25px rgba(99, 99, 99, 0.18);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.hidden {
  display: none;
}

#nav-profile-dropdown-username {
  font-weight: 300;
  padding-left: 10px;
  color: #283c5c;
}

.nav-profile-dropdown-links {
  font-weight: 600;
  padding: 7px 10px;
  border-radius: 10px;
  transition: background-color .4s;
  color: #283c5c;
  transition: background-color .5s, color .5s;
}

.nav-profile-dropdown-links:hover {
  cursor: pointer;
  color: #0844a4;
  background-color: rgba(39, 61, 251, 0.075);
}

#nav-profile-dropdown-logout {
  font-weight: 300;
  padding-left: 10px;
  color: #283c5c;
  transition: background-color .5s, color .5s;
}

#nav-profile-dropdown-logout:hover {
  color: #0844a4;
  background-color: rgba(39, 61, 251, 0.075);
}
