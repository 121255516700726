#card {
    z-index: -1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: .5px solid #cecece;
    padding: 10px 5px 10px 10px;
    border-radius: 14px;
    background-color: white;
    margin: 10px 0;
}

#card-btns {
    display: flex;
    flex-direction: row;
    gap: 2px;
}

#card-btns button {
    border-radius: 6px;
    background-color: #FFFFFF;
}

#card-btns button:hover {
    background-color: #ededed;
}

#card-btns i {
    color: #707070;
}
