.card-form-top-header {
    font-size: 24px;
    padding: 15px 0 5px 3px;
}

.card-error-text {
    color: rgba(159, 0, 0, 0.778);
    font-size: 11.5px;
}

/* #color-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 35px;
} */

/* #color-button-div {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #a11800;
} */

/* #color-button-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
} */

/* .red {
    background-color: #a11800;
}

.orange {
    background-color: #a15600;
}

.yellow {
    background-color: #b08307;
}

.green {
    background-color: #3a8501;
}

.blue {
    background-color: #016285;
}

.purple {
    background-color: #450185;
}

.light_gray {
    background-color: #cecece;
}

.medium_gray {
    background-color: #8f8f8f;
}

.dark_gray {
    background-color: #686868;
}

.black {
    background-color: #000000;
} */
