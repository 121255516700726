/* SITE-WIDE STYLES */

body {
    padding: 0;
    margin: 0;
}

/* TEXT */

* {
    color: #182c4c;
    font-family: 'Figtree', sans-serif;
    text-decoration: none;
}

/* BUTTON */

button {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

/* FOOTER */

#page-container {
    position: relative;
    min-height: 100vh;
}

#content-wrap {
    padding-bottom: 210px;
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 210px;
}

/* COLORS */

.logo-blue {
    color: #0864fc;
}

.nav-logo-blue {
    color: #283c5c;
}

.nav-link-blue {
    color: #182c4c;
}

.btn-hover-blue {
    color: #0844a4;
}

.accent-pink {
    color: #f054b4;
}

.accent-purple {
    color: #5844ac;
}

.font-blue-nav {
    color: #283c5c;
}

.font-blue-general {
    color: #101c44;
}

.bg-blue-footer {
    color: #182c4c;
}

.border-highlight-blue {
    color: #2596be;
}

.continue-btn-green {
    color: #60ac44;
}

.continue-btn-green-hover {
    color: #68bc4c;
}

.continue-btn-mint-green {
    color: #38b47c;
}

.continue-btn-mint-green-hover {
    color: #58dca4;
}
