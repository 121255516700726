.board-form-top-header {
    font-size: 24px;
    padding: 15px 0 5px 3px;
}

.board-error-text {
    color: rgba(159, 0, 0, 0.778);
    font-size: 11.5px;
}

#image-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 33px;
}

#image-button-div {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
    border: 1.5px solid #ffffff;
}

#image-button-div:hover {
    border: 1.5px solid #d3d3d3;
    box-shadow: 2px 2px 10px #bbbbbb;
}

.selected {
    border: 1.5px solid #8d8d8d !important;
}
