#footer-content {
    background-color: #182c4c;
    min-height: 210px;
}

#footer-row-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 38px 100px 33px 100px;
}

#footer-logo {
    font-size: 30px;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 20px;
    transition: color .7s;
}

#footer-logo:hover {
    color: #c6d2ff;
}

#footer-login button {
    color: #FFFFFF;
    background-color: #182c4c;
    padding: 0;
    margin: 0;
    font-size: 15.5px;
    transition: color .7s;
}

#footer-login button:hover {
    color: #c6d2ff;
}

#footer-logout {
    color: #FFFFFF;
    font-size: 15.5px;
    transition: color .7s;
    cursor: pointer;
}

#footer-logout:hover {
    color: #c6d2ff;
}

#footer-dev-info {
    margin-top: 3px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

#footer-reach-the-dev {
    color: #FFFFFF;
    font-size: 15.5px;
}

#footer-dev-name-and-links {
    font-size: 20.5px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 17px;
}

#footer-dev-name-and-links i {
    color: #FFFFFF;
    font-size: 23.5px;
    transition: color .7s, box-shadow .7s;
}

#footer-dev-name-and-links i:hover {
    color: #c6d2ff;
    box-shadow: 0px 0px 15px rgba(223, 223, 255, 0.25);
}

#footer-dev-name {
    color: #FFFFFF;
    font-size: 19.5px;
    font-weight: 100;
    transition: color .7s;
}

#footer-dev-name:hover {
    color: #c6d2ff;
}

#footer-dev-icons {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

#github-icon {
    border-radius: 50px;
}

#footer-row-2 {
    border-top: .5px solid rgb(93, 111, 155);
    padding: 25px 100px 23px 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

#footer-copyright {
    color: #FFFFFF;
    font-size: 12.5px;
}

#footer-tech {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 15px;
}

#footer-tech-used {
    margin-right: 5px;
}

#footer-tech span {
    color: #FFFFFF;
    font-size: 12.5px;
}

#footer-tech i {
    color: #FFFFFF;
    font-size: 12.5px;
}
