#board-page-content {
    padding: 25px 50px 800px 50px;
    display: flex;
    flex-direction: column;
}

#board-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

#board-title {
    margin: 15px 0;
    font-size: 30px;
    font-weight: 100;
}

#board-title-text {
    font-weight: 400;
}

#board-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

#board-btns button {
    border-radius: 6px;
    background-color: #FFFFFF;
}

#board-btns button:hover {
    background-color: #ededed;
}

#board-btns i {
    color: #707070;
}

#board-columns {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.board-one-column {
    min-width: 275px;
    max-width: 275px;
}

#board-add-col-btn button {
    min-width: 275px;
    max-width: 275px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    padding: 12.5px;

    border-radius: 9px;
    background-color: #ededed;
}

#board-add-col-btn button:hover {
    background-color: #e5e5e5;
}
