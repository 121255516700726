#boards-page {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 50px;
}

.dashboard-breadcrumb {
    margin-bottom: 5px;
}

#boards-header {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#boards-header-text {
    font-size: 40px;
}

#boards-header-add-btn button {
    min-width: 105px;
    max-width: 105px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    padding: 12.5px;
    margin-top: -3px;

    border-radius: 9px;
    background-color: #efefef;
    transition: background-color .5s, color .5s;
}

#boards-header-add-btn button:hover {
    color: #0844a4;
    background-color: rgba(39, 61, 251, 0.085);
}

#board-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

#boards-bottom-spacer {
    height: 100px;
}
