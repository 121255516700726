#home-banner-bg {
    z-index: -3;
    position: relative;
    background: linear-gradient(210deg, #2596be, rgba(0, 0, 0, 0) 65%),
        linear-gradient(120deg, #58dca4, rgba(0, 0, 0, 0) 25%),
        linear-gradient(800deg, #0864fc, rgba(0, 0, 0, 0) 75%);
    width: 100%;
    max-width: 100%;
    height: 490px;
    /* height: 547px; */
    /* height: 638.5px; */
    /* margin-bottom: 0; */
    bottom: 0;
    /* display: block; */
    /* object-fit: cover; */
}

#home-content {
    margin-left: 90px;
    /* background-color: #FFFFFF; */
}

#home-banner-text {
    margin-bottom: -140px;
}

#home-header {
    z-index: -1;
    position: relative;
    top: -395px;
    font-size: 50px;
    font-weight: 600;
    color: #FFFFFF;
}

#home-sub-header {
    z-index: -1;
    position: relative;
    top: -375px;
    font-size: 20.5px;
    font-weight: 500;
    color: #FFFFFF;
}

#home-signup-btn button {
    z-index: 0;
    position: relative;
    top: -345px;
    font-size: 16px;
    font-weight: 500;
    min-width: 165px;
    max-width: 165px;
    padding: 16px 0;
    color: #FFFFFF;
    background-color: #0864fc;
    border: none;
    border-radius: 6px;
    transition: background-color .5s;
}

#home-signup-btn button:hover {
    background-color: #0844a4;
}

#home-dashboard-btn {
    z-index: 0;
    position: relative;
    top: -345px;
    font-size: 16px;
    font-weight: 500;
    min-width: 185px;
    max-width: 185px;
    padding: 16px 0;
    color: #FFFFFF;
    background-color: #0844a4;
    border: none;
    border-radius: 6px;
    transition: background-color .5s;
}

#home-dashboard-btn:hover {
    background-color: #0864fc;
}

.home-content-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 60px;
}

.home-content-mini-header {
    color: #283c5c;
    font-size: 15px;
    font-weight: 600;
}

.home-content-section-header {
    font-size: 34px;
    font-weight: 600;
}

.home-content-section-text {
    margin-top: 8px;
    font-size: 19.5px;
    font-weight: 400;
    letter-spacing: .04rem;
    line-height: 1.75rem;
    width: 505px;
}

#home-bottom-spacer {
    height: 100px;
}
