#modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color: white;
  border-radius: 10px;
  padding: 55px 90px;
}

#modal-content h1 {
  font-size: 25px;
  display: flex;
  justify-content: center;
  margin-top: -5px;
  margin-bottom: -8px;
  color: #182c4c;
}

#modal-content form {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

#modal-content form ul {
  padding-left: 10px;
}

#modal-content form label {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  color: #182c4c;
}

#modal-content form input {
  margin-top: 2px;
  background-color: #FFFFFF;
  padding: 10px;
  border: 2px solid #dcdcdc;
  border-radius: 3px;
  /* width: 150px; */
}

#modal-content form input:focus {
  border: 2px solid #182c4c;
}

#modal-content form button {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  padding: 14px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  transition: background-color .5s;
}

#modal-login-signup button {
  font-weight: 600;
  color: #FFFFFF;
  background-color: #60ac44;
  transition: background-color .5s;
}

#modal-login-signup button:hover {
  background-color: #68bc4c;
}
