#notebook-details-page-content {
    padding: 25px 50px 800px 50px;
    display: flex;
    flex-direction: column;
}

#notebook-details-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

#notebook-details-title {
    margin: 15px 0;
    font-size: 30px;
    font-weight: 100;
}

#notebook-details-title-text {
    font-weight: 400;
}

#notebook-details-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

#notebook-details-btns button {
    border-radius: 6px;
    background-color: #FFFFFF;
}

#notebook-details-btns button:hover {
    background-color: #ededed;
}

#notebook-details-btns i {
    color: #707070;
}

#notebook-details-all-notes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.notebook-details-one-note {
    min-width: 275px;
    max-width: 275px;
}

#notebook-details-add-note-btn button {
    min-width: 155px;
    max-width: 155px;
    height: 30px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    padding: 7.5px 12px 5px 12px;

    border-radius: 3px;
    background-color: #FFFFFF;
    border: .5px solid #e1e1e1;
}

#notebook-details-add-note-btn button:hover {
    background-color: #ededed;
}
