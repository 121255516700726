#note {
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    border: .5px solid #e1e1e1;
    padding: 10px 9px 15px 15px;
    border-radius: 2.5px;
    background-color: white;
    margin: 10px 0;
}

#note-color-swatch-and-btns {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -10px;
}

#note-color-swatch {
    height: 7px;
    width: 15%;
    border-radius: 50px;
    margin-top: -4px;
}

.red {
    background-color: #a11800;
}

.orange {
    background-color: #a15600;
}

.yellow {
    background-color: #b08307;
}

.green {
    background-color: #3a8501;
}

.blue {
    background-color: #016285;
}

.purple {
    background-color: #450185;
}

.lightgray {
    background-color: #cecece;
}

.medgray {
    background-color: #8f8f8f;
}

.darkgray {
    background-color: #686868;
}

.black {
    background-color: #000000;
}

#note-title {
    font-size: 22px;
}

#note-btns {
    display: flex;
    flex-direction: row;
}

#note-btns button {
    border-radius: 6px;
    background-color: #FFFFFF;
}

#note-btns button:hover {
    background-color: #ededed;
}

#note-btns i {
    color: #bbbbbb;
}

#note-text {
    font-size: 17px;
}
