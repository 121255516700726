#board-card {
    display: flex;
    justify-items: start;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 5px;
    transition: box-shadow .5s;
}

#board-card:hover {
    box-shadow: 5px 5px 25px #cccccc;
}

#board-card-img-div {
    z-index: -2;
}

#board-card-img {
    width: 350px;
    height: 185px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: -4px;
}

#board-card-title {
    z-index: -1;
    position: absolute;
    margin: 20px;
    padding: 14px 18px;
    font-size: 20px;
    background-color: rgb(255 255 255 / 0.15);
    border-radius: 20px;
    box-shadow: 5px 15px 75px #FFFFFF;
}
